<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Leading Indicator Report</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6 m-b--6 lir" v-loading="loading">
      <div class="row">
        <div class="col-xl-12">
          <template>
            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-12 text-right">
                  <div class="row">
                    <div class="col-2">
                      <h4 class="mb-0 mt-2 float-left" id="lead_details_heading">Team Performance ({{$route.meta.country.toUpperCase()}})</h4>
                    </div>
                    <div class="col-2">
                      <div class="pl-4 text-sm mb-1">Report Type:</div>
                      <el-select
                        v-model="reportType"
                        collapse-tags
                        style="margin-left: 10px;width: 100%;"
                        placeholder="Select"
                        size="sm">
                        <el-option
                          label="Historical - Current month leads and resultant activity"
                          value="historical">
                        </el-option>
                        <el-option
                          label="Activity - Historic leads and current month activity"
                          value="activity">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-2">
                      <div class="pl-4 text-sm mb-1">Filter Dates:</div>
                      <el-select
                        v-model="selectedDates"
                        multiple
                        collapse-tags
                        style="margin-left: 10px;width: 100%;"
                        placeholder="Select">
                        <el-option
                          v-for="(date, index) in allDates" :key="index"
                          :label="date"
                          :value="date">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-2">
                      <div class="pl-4 text-sm mb-1" >Show Columns:</div>
                      <el-select
                        v-model="selectedColumns"
                        multiple
                        collapse-tags
                        style="margin-left: 10px;width: 100%;"
                        class="sales-rep-select"
                        placeholder="Select">
                        <el-option
                          v-for="(column, index) in columns" :key="index"
                          :label="column"
                          :value="column">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-2">
                      <div class="pl-4 text-sm mb-1" >Sales Reps:</div>
                      <el-select
                        v-model="includedVenueSpecialistsCountry"
                        multiple
                        collapse-tags
                        style="margin-left: 10px;width: 100%;"
                        @change="includedVenueSpecialistsChanged"
                        class="sales-rep-select"
                        placeholder="Select">
                        <el-option
                          v-for="(sales_rep, index) in venueSpecialists" :key="index"
                          :label="sales_rep.label"
                          :value="sales_rep.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-2">
                      <div class="pl-4 text-sm mb-1" >Lead Type:</div>
                      <el-select
                        v-model="leadType"
                        collapse-tags
                        style="margin-left: 10px;width: 100%;"
                        placeholder="All">
                        <el-option
                          label="All"
                          value="all">
                        </el-option>
                        <el-option
                          label="Corporate"
                          value="corporate">
                        </el-option>
                        <el-option
                          label="Wedding"
                          value="wedding">
                        </el-option>
                        <el-option
                          label="Social"
                          value="social">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <el-table
                  :stripe="true" height="750"
                  class="table-responsive table-flush report_data" 
                  header-row-class-name="thead-light" :data="leadingIndicatorReportsData" v-if="rerenderTable">
                  <el-table-column
                    label="Name"   prop="name" class="heading main"  min-width="120px"
                    :sortable="isSortable" v-if="selectedColumns.indexOf('Name') !== -1">
                    <template v-slot="{ row }">
                      <span :class="(row.name ? 'provide_border_top' : '')" >
                        <router-link v-if="row.id && !isSales" :to="{ name: individualRouteName, params: { venuespecialist: row.id } }">
                          <span class="sales_rep_name">{{row.name}}</span>
                        </router-link>
                        <span v-else class="sales_rep_name">{{row.name}}</span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Date"   prop="name" class="heading"  min-width="85px"
                    v-if="selectedColumns.indexOf('Date') !== -1"
                  >
                    <template v-slot="{ row }">
                      {{ row.date_name }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Leads" prop="leads" :sortable="isSortable"
                    v-if="selectedColumns.indexOf('Leads') !== -1">
                    <template v-slot="{ row }">
                      {{ row.leads}}<br/>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Actual" prop="actual_leads" :sortable="isSortable"
                    v-if="selectedColumns.indexOf('Actual') !== -1">
                    <template v-slot="{ row }">
                      <span
                        @mouseover="renderActualBreakDownTooltopContent(row);"
                        @mouseleave="actualBreakDownTooltipVisible[row.id + row.date_name] = false">
                        {{ row.actual_leads}} 
                        <span v-if="row.actual_leads_change">(-{{row.actual_leads_change.toFixed(0)}}%)</span><br/>
                        <el-tooltip placement="top" :manual="true" effect="dark" v-model="actualBreakDownTooltipVisible[row.id + row.date_name]">
                          <div slot="content" v-html="actualBreakDownTooltipContent"></div>
                          <span></span>
                        </el-tooltip>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Open" prop="open" :sortable="isSortable"
                    v-if="selectedColumns.indexOf('Open') !== -1">
                    <template v-slot="{ row }">
                      {{ row.open}}<br/>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Call Time" prop="call_time" :sortable="isSortable"
                    v-if="selectedColumns.indexOf('Call Time') !== -1">
                    <template v-slot="{ row }">
                      {{ row.call_time}}<br/>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Connections" prop="connections" min-width="100px" :sortable="isSortable"
                    v-if="selectedColumns.indexOf('Connections') !== -1">
                    <template v-slot="{ row }">
                      <span :class="'col-data '">{{ row.connections}} </span>
                      <span :class="'text-right text-' + row.connections_color">
                        <strong>({{(row.connections_avg).toFixed(0) }}%)</strong>
                      </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Site Visits" prop="site_visits"    :sortable="isSortable"
                    v-if="selectedColumns.indexOf('Site Visits') !== -1">
                    <template v-slot="{ row }">
                      <span :class="'col-data '">{{ row.site_visits}}</span>
                      <span :class="'text-' + row.site_visits_color">
                        <strong>({{ (row.site_visits_avg).toFixed(0) }}%)</strong>
                      </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Sales/Won %" prop="sales_won"   :sortable="isSortable"
                    v-if="selectedColumns.indexOf('Sales / Won %') !== -1">
                    <template v-slot="{ row }">
                      <span :class="'col-data '">{{ row.sales_won}}</span>
                      <span :class="'text-' + row.sales_won_color">
                        <strong>({{ (row.sales_won_avg).toFixed(0) }}%)</strong>
                      </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Conversion %" prop="funnel_conversion_rate" min-width="95px"  :sortable="isSortable"
                    :sort-method="sortPercentageConversionRate"
                    v-if="selectedColumns.indexOf('Conversion Rate') !== -1">
                    <template v-slot="{ row }">
                      
                      <span :class="'text-' + row.conversion_rate_connections_color">  {{ (row.conversion_rate_connections).toFixed(1) }}% </span> |
                      <span :class="'text-' + row.conversion_rate_leads_color">  {{ (row.conversion_rate_leads).toFixed(1) }}% </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Visits/Lead" prop="average_viewings_lead"  min-width="85px"   :sortable="isSortable"
                    :sort-method="sortPercentageAvgViewings"
                    v-if="selectedColumns.indexOf('Visits Per Lead') !== -1">
                    <template v-slot="{ row }">
                      <span :class="'text-' + row.visits_per_lead_color">
                        {{ (row.visits_per_lead).toFixed(1) }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
                <br/><br/>
              </div>
            </card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Tooltip } from "element-ui";
import moment from 'moment';
import ReportService from '@/services/reports.service';

const toTimeString = (value, singularName) => {
  let str = "";
  switch (singularName) {
  case "hr":
    str = ('00' + value).substring(('00' + value).length -2) +  ":";
    break;
  case "min":
    str = ('00' + value).substring(('00' + value).length -2);
    break;
  case "sec":
    str = "";
    break;
  case "day":
    str = `${value} ${singularName}${value !== 1 ? "s, " : ", "}`;
    break;
  }
  return str;
};
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      loading: true,
      reportType: 'Historical',
      leadType: 'all',
      country: '',
      selectedMonth: '',
      includedVenueSpecialistsCountry: [],
      excludedVenueSpecialists: [],
      selectedDates: [],
      rerenderTable: true,
      actualBreakDownTooltipVisible: [],
      actualBreakDownTooltipContent: '',
      selectedColumns: [
        'Name',
        'Date',
        'Leads',
        'Actual',
        'Open',
        'Connections',
        'Site Visits',
        'Sales / Won %',
        'Conversion Rate',
        'Visits Per Lead',
      ],
      columns: [
        'Name',
        'Date',
        'Leads',
        'Actual',
        'Open',
        'Call Time',
        'Connections',
        'Site Visits',
        'Sales / Won %',
        'Conversion Rate',
        'Visits Per Lead',
      ],
      moreDataFetched: false,
    };
  },
  async mounted() {
    this.selectedMonth = moment().format('YYYY-MM');
    await this.$store.dispatch('reports/getExcludedLeadingIndicatorVCs');
    await this.$store.dispatch('venues/getVenueSpecialists');
    this.excludedVenueSpecialists = this.$store.getters["reports/getExcludedLeadingIndicatorVCs"]();
    this.assignIncludedVCsByCountry();
  },
  updated() {
    this.$nextTick(() => {
      this.updateReportSeparationBorder();
    });
  },
  computed: {
    leadingIndicatorReportsData() {
      let report = this.$store.getters["reports/getLeadingIndicatorReports"](
        this.$route.meta.country,
        this.selectedMonth,
        (this.isSales ? this.authVenueSpecialistOwnerId : '')
      ).report;
      let data = []; 
      let allData = [];
      for (let key in report) {
        let sales_rep = report[key];
        let name_added = false;
        for (let key_data in sales_rep.data) {
          let _data = sales_rep.data[key_data];
          if (!this.selectedDates.includes(_data.date_name)) {
            continue;
          }
          // Uncomment if, hide all except sales rep data for sales rep view
          // if (
          //   (this.isSales && this.authVenueSpecialist && sales_rep.venue_specialist.id !== this.authVenueSpecialist)
          //   && sales_rep.venue_specialist.id) {
          //   continue;
          // }

          let name = !name_added ? sales_rep.name : '';
          name_added = true;
          let item = {
            id: sales_rep.venue_specialist.id,
            name: name,
            date_name: _data.date_name,
            leads: _data.leads,
            open: _data.open,
            call_time: this.readableTime(_data.call_time),
            actual_leads: _data.actual_leads,
            connections: _data.connections,
            connections_avg: _data.actual_leads ? (_data.connections / _data.actual_leads * 100) : 0,
            site_visits: _data.site_visits,
            site_visits_avg: _data.connections ? (_data.site_visits / _data.connections * 100) : 0,
            sales_won: _data.sales_won,
            sales_won_avg: _data.viewings ? (_data.sales_won / _data.viewings * 100) : 0,
            leads_with_viewings: _data.leads_with_viewings,
            conversion_rate_leads: _data.leads ? (_data.sales_won / _data.leads * 100) : 0,
            conversion_rate_connections: _data.connections ? (_data.sales_won / _data.connections * 100) : 0,
            visits_per_lead: _data.leads_with_viewings ? (_data.viewings / _data.leads_with_viewings) : 0,
            actual_leads_change: _data.leads ? ((_data.leads - _data.actual_leads) / _data.leads) * 100 : 0,
            leads_by_status: _data.leads_by_status,
          };
          if (!sales_rep.venue_specialist.id) {
            allData[_data.date_name] = item;
          }
          // Uncomment if, hide all except sales rep data for sales rep view
          // if (this.isSales && !sales_rep.venue_specialist.id) {
          //   continue;
          // }
          data.push(item);
        }
      }
      data = this.addPerfomanceColours(data, allData);
      return data;
    },
    allDates() {
      let _dates = this.$store.getters["reports/getLeadingIndicatorReports"](
        this.$route.meta.country,
        this.selectedMonth
      ).date_values;
      return _dates;
    },
    individualRouteName() {
      return 'SingleLeadingIndicatorReport' + this.$route.meta.country.toUpperCase();
    },
    venueSpecialists() {
      let vcs = this.$store.state.venues.venueSpecialists;
      let data = [];
      for (let vc in vcs) {
        vc = vcs[vc];
        if(vc.country.toLowerCase() !== this.$route.meta.country) {
          continue;
        }
        // Uncomment if, hide all except sales rep data for sales rep view
        // if (this.isSales && this.authVenueSpecialist && vc.id !== this.authVenueSpecialist) {
        //   continue;
        // }
        data.push({
          label: vc.name,
          value:  vc.id,
        });
       
      }
      data.sort(function(a,b){
        a = a['label'];
        b = b['label'];
        return (a === b) ? 0 : (a < b) ? -1 : 1;
      });

      return data;
    },
    isSortable() {
      if (this.selectedDates.length === 1) {
        return true;
      }
      return false;
    },
    authVenueSpecialist() {
      let user = this.$store.getters["auth/getUser"];
      return user.data.venue_specialist && user.data.venue_specialist.venue_specialist_id;
    },
    authVenueSpecialistOwnerId() {
      let user = this.$store.getters["auth/getUser"];
      return user.data.venue_specialist && user.data.venue_specialist.hubspot_owner_id;
    },
    isSales() {
      return this.$store.getters["auth/isSales"];
    },
    currentlyFetchedDates() {
      let report = this.$store.getters["reports/getLeadingIndicatorReports"](
        this.$route.meta.country,
        this.selectedMonth
      ).report;
      let _dates = [];
      for (let key in report) {
        let sales_rep = report[key];
        for (let key_data in sales_rep.data) {
          let _data = sales_rep.data[key_data];
          _dates.push(_data.date_name);
        }
        break;
      }
      return _dates;
    },
  },
  watch: {
    selectedMonth() {
      this.getReport();
    },
    reportType() {
      this.getReport();
    },
    leadType() {
      this.getReport();
    },
    '$route.meta.country': {
      handler: async function(search) {
        if (this.selectedMonth) {
          this.loading = true;
          await this.$store.dispatch('reports/getLeadingIndicators', {
            country: this.$route.meta.country,
            month: this.selectedMonth,
            force_include_venuespecialist_id: (this.isSales ? this.authVenueSpecialist : ''),
            report_type: this.reportType,
          });
          this.loading = false;
        }
        this.assignIncludedVCsByCountry();
        this.selectedDates = this.currentlyFetchedDates;
      },
      deep: true,
      immediate: true,
    },
    allDates() {
      if (this.selectedDates.length === 0) {
        this.selectedDates = this.currentlyFetchedDates;
      }
    },
    selectedColumns() {
      this.rerenderTable = false;
      this.$nextTick(async () => {
        // Add the component back in
        this.rerenderTable = true;
      });
    },
    selectedDates() {
      this.rerenderTable = false;

      this.$nextTick(async () => {
        if (this.selectedDates.length > this.currentlyFetchedDates.length) {
          this.loading = true;
          await this.$store.dispatch('reports/getLeadingIndicators', {
            country: this.$route.meta.country,
            month: this.selectedMonth,
            force_include_venuespecialist_id: (this.isSales ? this.authVenueSpecialist : ''),
            load_all: 1,
            report_type: this.reportType,
          });
          this.moreDataFetched = true;
          this.loading = false;
        }
        // Add the component back in
        this.rerenderTable = true;
      });
    },
    currentlyFetchedDates() {
      if (this.selectedDates.length > this.currentlyFetchedDates.length) {
        this.selectedDates = this.currentlyFetchedDates;
      }
    },
  },
  methods: {
    readableTime: (call_time) => {
      let ms = call_time.call_time_avg;
      const days = Math.floor(ms / (24 * 60 * 60 * 1000));
      const daysMs = ms % (24 * 60 * 60 * 1000);
      const hours = Math.floor(daysMs / (60 * 60 * 1000));
      const hoursMs = ms % (60 * 60 * 1000);
      const minutes = Math.floor(hoursMs / (60 * 1000));
      const minutesMs = ms % (60 * 1000);
      const seconds = Math.round(minutesMs / 1000);

      let timeStr = '';
      timeStr += (days) ? toTimeString(days, 'day') : '';
      timeStr += toTimeString(hours, 'hr');
      timeStr += toTimeString(minutes, 'min') ;
      return timeStr + ' (' + call_time.total + ')';
    },
    getDateWiseAllDataPerformance(userData, allData) {
      return allData[userData.date_name];
    },
    addPerfomanceColours(data, allData) {
      for (let key_data in data) {
        if (data[key_data].id ) {
          let userData = data[key_data];
          let allDataDate = this.getDateWiseAllDataPerformance(userData, allData);
          data[key_data].connections_color = Math.round(allDataDate.connections_avg) <= Math.round(userData.connections_avg) ? 'success' : 'danger';
          data[key_data].site_visits_color = Math.round(allDataDate.site_visits_avg) <= Math.round(userData.site_visits_avg) ? 'success' : 'danger';
          data[key_data].sales_won_color = Math.round(allDataDate.sales_won_avg) <= Math.round(userData.sales_won_avg) ? 'success' : 'danger';
          data[key_data].conversion_rate_connections_color = Math.round(allDataDate.conversion_rate_connections) <= Math.round(userData.conversion_rate_connections) ? 'success' : 'danger';
          data[key_data].conversion_rate_leads_color = Math.round(allDataDate.conversion_rate_leads) <= Math.round(userData.conversion_rate_leads) ? 'success' : 'danger';
          data[key_data].visits_per_lead_color = Math.round(allDataDate.visits_per_lead) <= Math.round(userData.visits_per_lead) ? 'success' : 'danger';
        }
      }
      return data;
    },
    getExcludedFromDiffCountry() {
      let vcs = this.$store.state.venues.venueSpecialists;
      let data = [];
      for (let vc in vcs) {
        vc = vcs[vc];
        if(vc.country.toLowerCase() === this.$route.meta.country ) {
          continue;
        }
        if (this.excludedVenueSpecialists.includes(vc.id)) {
          data.push(vc.id);
        }
      }
      return data;
    },
    async includedVenueSpecialistsChanged() {
      let excludedInDiffCountry = this.getExcludedFromDiffCountry();
      this.excludedVenueSpecialists = this.venueSpecialists.filter(x => !this.includedVenueSpecialistsCountry.includes(x.value)).map(x => x.value);
      this.excludedVenueSpecialists = this.excludedVenueSpecialists.concat(excludedInDiffCountry);
      await ReportService.updateExcludedLeadingIndicatorVCs(this.excludedVenueSpecialists);
      await this.$store.dispatch('reports/getExcludedLeadingIndicatorVCs');
      this.getReport();
    },
    async getReport() {
      this.loading = true;
      await this.$store.dispatch('reports/getLeadingIndicators', {
        country: this.$route.meta.country,
        month: this.selectedMonth,
        force_include_venuespecialist_id: (this.isSales ? this.authVenueSpecialist : ''),
        load_all: this.moreDataFetched,
        report_type: this.reportType,
        lead_type: this.leadType,
      });
      this.loading = false;
    },
    assignIncludedVCsByCountry() {
      let temp = [];
      for (let vc in this.venueSpecialists) {
        if (!this.excludedVenueSpecialists.includes(this.venueSpecialists[vc].value)) {
          temp.push(this.venueSpecialists[vc].value);
        }
      }
      this.includedVenueSpecialistsCountry = temp;
    },
    updateReportSeparationBorder() {
      // document.getElementsByClassName('provide_border_top_tr').forEach(function(e) {
      //   e.classList.remove("provide_border_top_tr");
      // });
      // document.getElementsByClassName('provide_border_top').forEach(function(e) {
      //   e.parentElement.parentElement.parentElement.classList.add("provide_border_top_tr");;
      // });
      let len = this.selectedDates.length;
      let adjusting_factor = 1;
      let incre = 0;
      
      document.getElementsByTagName('tbody').forEach(function(e) {
        e.children.forEach(function(e) {
          incre += 1;
          e.classList.remove("provide_border_top_tr");
          if (!((incre % len) - adjusting_factor)) {
            e.classList.add("provide_border_top_tr");
          }
          
        });
      });
    },
    sortPercentageConversionRate(a, b) {
      let aValue = a.conversion_rate;
      let bValue = b.conversion_rate;
      return aValue - bValue ;
    },
    sortPercentageAvgViewings(a, b) {
      let aValue = a.visits_per_lead;
      let bValue = b.visits_per_lead;
      return aValue - bValue;
    },
    renderActualBreakDownTooltopContent(row) {
      this.actualBreakDownTooltipVisible[row.id + row.date_name] = true; 
      let content = '';
      let data = row.leads_by_status;
      data.sort(function(a,b){
        a = a['value'];
        b = b['value'];
        return (a === b) ? 0 : (a > b) ? -1 : 1;
      });
      for (let key in data) {
        let label = data[key].label;
        let value = data[key].value;
        let per = row.leads ? ( value / row.leads ) * 100 : 0;
        content += label + ' - ' + value + ' (' +  per.toFixed(0) + '%)<br/>';
      }
      this.actualBreakDownTooltipContent = content;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(246, 249, 252, 1);
}

div.el-table tbody td, div.el-table thead th {
    padding: 0.5rem;
} 

tr.provide_border_top_tr td {
  border-top: 3px solid #DDE2EC;
}
.sales-rep-select .el-select__tags {
  max-width: 230px !important;
}
.lir .el-table td,
.lir .el-table th {
  padding: .6em .3em !important;
}

div.el-table .cell {
  font-size: 14px;
}

div.el-table .el-table__row:hover {
  background: #1171ef1d;
}

.col-data {
    float: left;
    width: 50px;
}

</style>
